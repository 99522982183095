import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import "./ContactForm.scss";

export function ContactForm() {
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      message: "",
    },
    validate: (values) => {
      const errors: {
        email?: string;
        name?: string;
        message?: string;
      } = {};
      if (!values.email) {
        errors.email = "The email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      if (!values.name) {
        errors.name = "The name and surname is required";
      }

      if (!values.message) {
        errors.message = "The message field is required";
      }

      return errors;
    },
    onSubmit: async (formData) => {
      try {
        await axios.post(
          "https://us-central1-zencode-d5b2a.cloudfunctions.net/contact",
          formData
        );

        setIsAlreadySubmitted(true);
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="inputWrapper">
        <input
          name="name"
          placeholder="Your name & surname"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.errors.name ? (
          <span className="error">{formik.errors.name}</span>
        ) : null}
      </div>

      <div className="inputWrapper">
        <input
          name="email"
          placeholder="Your email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        {formik.errors.email ? (
          <span className="error">{formik.errors.email}</span>
        ) : null}
      </div>
      <div className="inputWrapper">
        <input
          name="message"
          placeholder="Your message"
          value={formik.values.message}
          onChange={formik.handleChange}
        />
        {formik.errors.message ? (
          <span className="error">{formik.errors.message}</span>
        ) : null}
      </div>
      {isAlreadySubmitted && (
        <div>
          <span className="submit-success">
            Your message has been sent! We'll get back to you as soon as
            possible!
          </span>
        </div>
      )}
      <div>
        <button
          className="primary"
          type="submit"
          disabled={isAlreadySubmitted || (formik.touched && !formik.isValid)}
        >
          Send message
        </button>
      </div>
    </form>
  );
}

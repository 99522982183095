import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import "./index.scss";
import ScrollProgress from "../components/ScrollProgress";
import Layout from "../components/Layout";
import BackgroundInversionBand from "../components/BackgroundInversionBand";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import { ContactForm } from "../components/ContactForm";
import quizAppLayer1 from "../images/quiz_app_layer_1.png";
import quizAppLayer2 from "../images/quiz_app_layer_2.png";
import quizAppLayer3 from "../images/quiz_app_layer_3.png";
import quizAppLayer4 from "../images/quiz_app_layer_4.png";
import dietAppLayer1 from "../images/diet_app_layer_1.png";
import dietAppLayer2 from "../images/diet_app_layer_2.png";
import dietAppLayer3 from "../images/diet_app_layer_3.png";
import dietAppLayer4 from "../images/diet_app_layer_4.png";
import chatAppLayer1 from "../images/chat_app_layer_1.png";
import chatAppLayer2 from "../images/chat_app_layer_2.png";
import chatAppLayer3 from "../images/chat_app_layer_3.png";
import chatAppLayer4 from "../images/chat_app_layer_4.png";
import programmerAnimation from "../images/programmer.json";

const IndexPage = () => {
  const heroRef = useRef<HTMLDivElement | null>(null);
  const project1Ref = useRef<HTMLDivElement | null>(null);
  const project2Ref = useRef<HTMLDivElement | null>(null);
  const project3Ref = useRef<HTMLDivElement | null>(null);
  const whatWeDoRef = useRef<HTMLDivElement | null>(null);
  const servicesRef = useRef<HTMLDivElement | null>(null);
  const contactRef = useRef<HTMLDivElement | null>(null);
  const [scrollPos, setScrollPos] = useState(0);

  const handleScroll = () => {
    requestAnimationFrame(() => {
      setScrollPos(window.pageYOffset);
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const translate = (x: number, y: number, multiplier: number) => {
    const offsetY = (scrollPos / 100) * 3 * multiplier;
    return { transform: `translate(${x}px, ${y - offsetY}px)` };
  };

  const scrollTo = (sectionRef: any) => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollToHero = () => scrollTo(heroRef);

  const handleScrollToWork = () => {
    scrollTo(project1Ref);
  };

  const handleScrollToAbout = () => {
    scrollTo(whatWeDoRef);
  };

  const handleScrollToService = () => {
    scrollTo(servicesRef);
  };

  const handleScrollToContact = () => {
    scrollTo(contactRef);
  };

  const sectionsIntersections = [
    useIntersectionObserver(heroRef, {
      threshold: 0.8,
    }),
    useIntersectionObserver(project1Ref, {
      threshold: 0.8,
    }),
    useIntersectionObserver(project2Ref, {
      threshold: 0.8,
    }),
    useIntersectionObserver(project3Ref, {
      threshold: 0.8,
    }),
    useIntersectionObserver(whatWeDoRef, {
      threshold: 0.8,
    }),
    useIntersectionObserver(servicesRef, {
      threshold: 0.8,
    }),
    useIntersectionObserver(contactRef, {
      threshold: 0.8,
    }),
  ];

  return (
    <Layout
      onScrollToHero={handleScrollToHero}
      onScrollToWork={handleScrollToWork}
      onScrollToAbout={handleScrollToAbout}
      onScrollToService={handleScrollToService}
      onScrollToContact={handleScrollToContact}
      pageTitle="Zen code - Digital creation agency"
    >
      <ScrollProgress sections={sectionsIntersections} />
      <section className="hero screen-height" ref={heroRef}>
        <div className="hero__overlay"></div>
        <div className="hero__content">
          <h1>
            We build <br /> state of the art <br /> web and mobile
            <br />
            experiences.
          </h1>
          <p>
            We create value by designing and developing amazing and unique web
            applications and websites.
          </p>
          <button className="primary" onClick={handleScrollToWork}>
            See what we do
          </button>
        </div>
      </section>

      <section className="work ">
        <BackgroundInversionBand position="left" />
        <BackgroundInversionBand position="right" />

        <h2 className="work__title">Some of our work</h2>
        <div className="work__inner screen-height" ref={project1Ref}>
          <div className="work__inner__image">
            <img style={translate(-60, 20, 1)} src={quizAppLayer1} alt="Team" />
            <img style={translate(-20, 10, 2)} src={quizAppLayer2} alt="Team" />
            <img style={translate(20, 0, 3)} src={quizAppLayer3} alt="Team" />
            <img style={translate(60, -10, 4)} src={quizAppLayer4} alt="Team" />
          </div>
          <div className="work__inner__content">
            <h4>
              <span>01.</span> Quiz app
            </h4>

            <p>
              This is a self sponsored application with the goal of making an
              impact in the development community. We curated a list of a
              thousand software engineering questions, designed to train
              engineers of all levels to ace their interview.
            </p>
            {/* <button className="primary">Check it out</button> */}
          </div>
        </div>

        <div className="work__inner screen-height" ref={project2Ref}>
          <div className="work__inner__content">
            <h4>
              <span>02.</span> Diet tracking app
            </h4>

            <p>
              We've developed a diet tracking application which adapts to each
              unique metabolism on earth by following a simple mathematical
              algorithm. As long as you use the application, and you add the
              foods you eat, the application will learn your metabolism and will
              be able to suggest foods to lose or gain weight.
            </p>
            {/* <button className="primary">Check it out</button> */}
          </div>
          <div className="work__inner__image">
            <img style={translate(60, 10, 1)} src={dietAppLayer1} alt="Team" />
            <img style={translate(20, 0, 1.7)} src={dietAppLayer2} alt="Team" />
            <img
              style={translate(-20, -10, 2.4)}
              src={dietAppLayer3}
              alt="Team"
            />
            <img
              style={translate(-60, -20, 3.1)}
              src={dietAppLayer4}
              alt="Team"
            />
          </div>
        </div>

        <div className="work__inner screen-height" ref={project3Ref}>
          <div className="work__inner__image">
            <img style={translate(-60, 20, 1)} src={chatAppLayer1} alt="Team" />
            <img
              style={translate(-20, 10, 1.5)}
              src={chatAppLayer2}
              alt="Team"
            />
            <img style={translate(20, 0, 2)} src={chatAppLayer3} alt="Team" />
            <img
              style={translate(60, -10, 2.5)}
              src={chatAppLayer4}
              alt="Team"
            />
          </div>
          <div className="work__inner__content">
            <h4>
              <span>03.</span> Chat app
            </h4>

            <p>
              Our client asked us to build an application that allows the users
              to chat with themselsves. Why? Because it's actually a great way
              to take notes for stuff you have to do in the future and keep
              track of your thoughts.
            </p>
            {/* <button className="primary">Check it out</button> */}
          </div>
        </div>
      </section>

      <section className="what-we-do screen-height" ref={whatWeDoRef}>
        <BackgroundInversionBand position="left" />
        <BackgroundInversionBand position="right" />
        <div className="what-we-do__content">
          <h4>What we do</h4>
          <p>
            We create web & mobile applications that are unique and high
            quality.
          </p>
        </div>
      </section>

      <section className="services screen-height" ref={servicesRef}>
        <BackgroundInversionBand position="left" />
        <BackgroundInversionBand position="right" />
        <div className="services__inner">
          <div className="services__team_image">
            <Lottie animationData={programmerAnimation} loop={true} />
          </div>
          <div className="services__content">
            <div className="services__content__inner">
              <h2>Services</h2>
              <ul>
                <li>
                  <span>01.</span>Digital Consultancy
                </li>
                <li>
                  <span>02.</span>Web Design
                </li>
                <li>
                  <span>03.</span>Web & Mobile Development
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="contact screen-height" ref={contactRef}>
        <BackgroundInversionBand position="left" />
        <BackgroundInversionBand position="right" />
        <div className="contact__content">
          <h4>Get in touch!</h4>
          <div className="contact__content__inner">
            <div className="contact__content__data">
              <h5>Contact</h5>

              <p>Email: hello@zen-code.ro</p>
              <p>Phone: +40 720 205 804</p>
            </div>
            <ContactForm />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

import React, { useState, useEffect } from "react";
import "./ScrollProgress.scss";

interface Props {
  sections: (IntersectionObserverEntry | undefined)[];
}

const Bullet = ({ active }: { active: boolean }) => (
  <div
    className={
      active ? "scroll-progress__bullet active" : "scroll-progress__bullet"
    }
  ></div>
);

const ScrollProgress = ({ sections = [] }: Props) => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const activeIndex = sections.findIndex(
      (intersection) => intersection?.isIntersecting
    );

    if (activeIndex !== -1) {
      setActive(activeIndex);
    }
  }, [sections]);

  return (
    <div className="scroll-progress">
      <div className="scroll-progress__inner">
        {sections.map((_, index) => (
          <Bullet key={index} active={index === active} />
        ))}
      </div>
    </div>
  );
};

export default ScrollProgress;

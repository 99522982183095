import React from "react";
import "./BackgroundInversionBand.scss";

interface Props {
  position: "left" | "right";
}

const BackgroundInversionBand = ({ position }: Props) => {
  return (
    <div className="inversion-band">
      <div
        style={{ [position]: 0 }}
        className="inversion-band__foreground"
      ></div>
      <div
        style={{ [position]: 0 }}
        className="inversion-band__background"
      ></div>
    </div>
  );
};

export default BackgroundInversionBand;
